<template>
  <pop-up :width="width" :closePopupEvent="closePopupCB">
    <div slot="header"></div>
    <div class="about-container">
      <div class="about-logo">
        <img :src="vendorLogo" alt="logo" loading="lazy" />
        <p class="text-style">v1.0.1</p>
      </div>
      <div class="about-text">
        <p class="text-style">{{aboutText}}</p>
      </div>
      <div class="about-social-icons">
        <p class="boldStyle">Follow us on</p>
        <!-- href="https://www.facebook.com/mobiotics" -->
        <a :href="facebookLink" target="_blank" rel="noopener" aria-label="facebook link">
          <img src="@/assets/icons/facebook-logo.svg" alt="facebook logo" loading="lazy" />
        </a>
        <!-- href="https://about/social/pinterest" -->
        <a :href="pinterestLink" target="_blank" rel="noopener" aria-label="pinterest link">
          <img src="@/assets/icons/pinterest-logo.svg" alt="pinterest logo" loading="lazy" />
        </a>
        <!-- href="https://twitter.com/mobiotics" -->
        <a :href="twitterLink" target="_blank" rel="noopener" aria-label="twitter link">
          <img src="@/assets/icons/twitter-logo.svg" alt="twitter logo" loading="lazy" />
        </a>
        <!-- href="https://twitter.com/mobiotics" -->
        <a :href="instagramLink" target="_blank" rel="noopener" aria-label="instagram link">
          <img src="@/assets/icons/instagram-logo.svg" alt="instagram logo" loading="lazy" />
        </a>
      </div>
      <div class="powerd-by">
        <span>
            <a class="website" href="https://mobiotics.com/" target="_blank">
                        Powered by Mobiotics
            </a>
        </span>
      </div>
    </div>
  </pop-up>
</template>

<script>
import { EventBus } from "@/main";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "About",
  data() {
    return {
      width: "50%",
      aboutText: "",
      facebookLink: "",
      twitterLink: "",
      pinterestLink: "",
      instagramLink: "",
      vendorLogo :""
    };
  },
  props: ["closePopupCB"],
  computed: {
    ...mapGetters(["appConfig", "vendorAssets"])
  },
  methods: {
    closePopup() {
      this.closePopupCB();
    },
    basicDisplays() {
      if (this.appConfig && this.appConfig.vendorDetails) {
        this.aboutText = this.appConfig.vendorDetails.abouttext
          ? this.appConfig.vendorDetails.abouttext
          : "";
      }
      if (
        this.appConfig &&
        this.appConfig.vendorDetails &&
        this.appConfig.vendorDetails.social
      ) {
        this.facebookLink = this.appConfig.vendorDetails.social.facebook
          ? this.appConfig.vendorDetails.social.facebook
          : "";
        this.instagramLink = this.appConfig.vendorDetails.social.instagram
          ? this.appConfig.vendorDetails.social.instagram
          : "";
        this.twitterLink = this.appConfig.vendorDetails.social.twitter
          ? this.appConfig.vendorDetails.social.twitter
          : "";
        this.pinterestLink = this.appConfig.vendorDetails.social.pinterest
          ? this.appConfig.vendorDetails.social.pinterest
          : "";
      }
    }
  },
  created() {
    this.vendorLogo =  this.vendorLogo = require(`@/assets/icons/logo/${this.vendorAssets}/logo.png`);
    this.basicDisplays();
  },
  components: {
    "pop-up": () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue")
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.text-style {
  color: $font-clr-gray;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 1rem;
  line-height: 1.5rem;
}
.boldStyle {
  color: $font-clr-white-1;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 1.2rem;
}
.about-container {
  padding: 10%;
  .about-logo {
    text-align: center;
    padding-bottom: 5%;
    img {
      width: 15%;
    }
  }
  .about-text {
    padding-bottom: 5%;
    p {
      text-align: center;
    }
  }
  .powerd-by {
    text-align: center;
    .website{
      color: $font-clr-gray-1;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 0.9rem;
       &:hover {
        color: $font-clr-gray;
      }
    }
  }
  .about-social-icons {
    text-align: center;
    padding: 5% 0;
    margin-bottom: 3%;
    p {
      padding: 2%;
    }
    :nth-child(2) {
      margin-left: 0px;
    }
    a {
      margin-left: 20px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
    img {
      flex: 1;
      width: 25px;
      height: 25px;

      cursor: pointer;
    }
  }
}
</style>